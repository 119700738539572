<template>
  <div class="btn-group" v-if="device==='desktop'">
    <button class="btn btn-outline-primary dropdown-toggle" type="button" :class="{'disabled' : filterDisabled}" ref="durationFilter"
    id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" @click="() => showedDropdown()">
    {{(isFiltered) ? $t("sabre.search-panel.filtered.go-up-to", {hour: duration1}) : $t("sabre.search-panel.duration")}}
    </button>
    <div class="dropdown-menu dropdownranger" aria-labelledby="dropdownMenuClickableInside">

      <one-way-slider v-model="duration1" :min="0" :max="maxDuration1"/>
      <one-way-slider v-model="duration2" :min="0" :max="maxDuration2" v-if="!isOneWay"/>

      <div class="btn-group-area d-flex justify-content-center">
        <button class="cleanup_btn" @click="() => clean()">{{$t("sabre.buttons.clean")}}</button>
        <button class="save_btn" @click="() => save()">{{$t("sabre.buttons.save")}}</button>
      </div>
    </div>
  </div>

  <div class="accordion-item" v-else>
    <h2 class="accordion-header" id="heading1">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :class="{'disabled' : filterDisabled}"
      data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapseOne">
        {{$t("sabre.search-panel.duration")}}
      </button>
    </h2>
    <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample">
      <div class="accordion-body dropdownranger ">

        <one-way-slider v-model="duration1" :min="0" :max="maxDuration1"/>
        <one-way-slider v-model="duration2" :min="0" :max="maxDuration2" v-if="!isOneWay"/>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import oneWaySlider from '@/sabre/common/atom/oneWaySlider';
import filterControlMixin from '@/sabre/fo/searchResult/filterPanel/filterControlMixin';

export default {
  mixins: [filterControlMixin],
  props: ['type'],
  components: { oneWaySlider },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
    maxDuration1() {
      const { filterConditions } = this;
      return filterConditions?.maxDuration1 || 20;
    },
    maxDuration2() {
      const { filterConditions } = this;
      return filterConditions?.maxDuration2 || 20;
    },
    isFiltered() {
      return this.filterApplied && (this.duration1 !== this.maxDuration1 || this.duration2 !== this.maxDuration2);
    },
  },
  watch: {
    duration1() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'durFilterValue1', value: this.duration1 });
    },
    duration2() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'durFilterValue2', value: this.duration2 });
    },
    durFilterValue1() {
      this.duration1 = this.durFilterValue1;
    },
    durFilterValue2() {
      this.duration2 = this.durFilterValue2;
    },
  },
  data() {
    return {
      duration1: 0,
      duration2: 0,
      filterApplied: false,
    };
  },
  mounted() {
    this.duration1 = this.durFilterValue1;
    this.duration2 = this.durFilterValue2;
  },
  methods: {
    clean() {
      this.duration1 = this.maxDuration1;
      this.duration2 = this.maxDuration2;
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'durFilterValue1', value: this.duration1 });
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'durFilterValue2', value: this.duration2 });
    },
    save() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.durationFilter).hide();
      this.filterApplied = true;
      this.applyFilter();
    },
    showedDropdown() {
      this.filterApplied = false;
    },
  },
};
</script>
