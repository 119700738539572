<template>
  <div class="hours_slider">
    <div class="hours" :ref="`one-way-slider-${_uid}`"></div>
    <p class="d-flex align-items-center">
      <label for="amount" class="m-0"> {{$t("sabre.search-result.less-than")}}-
        <span class="amount">{{ amount }} {{$t("sabre.search-result.hours")}}</span> </label>
    </p>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'one-way-slider',
  props: ['amount', 'min', 'max'],
  model: {
    prop: 'amount',
    event: 'change',
  },
  watch: {
    min() {
      this.jqueryObject.slider('option', 'min', this.min);
    },
    max() {
      this.jqueryObject.slider('option', 'max', this.max);
    },
    amount() {
      this.jqueryObject.slider('option', 'value', this.amount);
    },
  },
  data() {
    return {
      jqueryObject: null,
    };
  },
  mounted() {
    this.create();
  },
  methods: {
    create() {
      const vm = this;
      this.jqueryObject = $(this.$refs[`one-way-slider-${this._uid}`]);

      this.jqueryObject.slider({
        value: this.amount,
        isRTL: true,
        min: this.min,
        max: this.max,
        orientation: 'horizontal',
        range: 'min',
        animate: true,
        slide(event, ui) {
          vm.$emit('change', ui.value);
        },
      });
    },
  },
};
</script>
